// const getRegionalLegalForm = region => region === 1
//   ? import(/* webpackChunkName: "ProjectInfoEditLegalBrussels" */ './projectLegalBrussels')
//   : import(/* webpackChunkName: "ProjectInfoEditLegal" */ './projectLegal')

const projectInfoForms = {
  energy: () => import(/* webpackChunkName: "ProjectInfoEditEnergy" */ './energy'),
  features: () => import(/* webpackChunkName: "ProjectInfoEditFeatures" */ './features'),
  settings: () => import(/* webpackChunkName: "ProjectInfoEditSettings" */ './settings'),
  website: () => import(/* webpackChunkName: "ProjectInfoEditWebsite" */ './website')
}

const projectGeneralForms = {
  general: () => import(/* webpackChunkName: "ProjectGeneralEdit" */ './general'),
  comments: () => import(/* webpackChunkName: "ProjectGeneralEditComments" */ './comments'),
  collaborators: () => import(/* webpackChunkName: "ProjectGeneralEditCollaborators" */ './collaborators'),
  offices: () => import(/* webpackChunkName: "ProjectGeneralEditOffices" */ './offices')
}

const forms = { ...projectInfoForms, ...projectGeneralForms }

async function getForm (name, project) {
  // Find the form and import it
  const response = await forms[name]?.(project)
  return response?.default
}

export default getForm
