<template>
  <div>
    <h2 class="tw-mt-2 tw-mb-8">
      <router-link
        :to="backRoute"
        title="Ga terug"
        class="!tw-text-tg-color hover:tw-opacity-80"
      >
        <i class="fal fa-arrow-left tw-mr-2" />
      </router-link>
      <!-- In case the form doesn't exist, show "Ga terug" to help user go back -->
      {{ form.heading || 'Ga terug' }}
    </h2>
    <transition name="fade" mode="out-in">
      <!-- We check for the entity to avoid loading default form and then switch to region/sales-type based form -->
      <FormulateForm
        #default="{ isLoading }"
        v-if="entity && form.actions"
        v-model="values"
        v-bind="form"
        invalid-message="Gelieve de verplichte velden correct in te vullen."
        @created="loadData"
        @submit="submit"
        @streetSelected="streetSelected"
        @epbInDraftChange="handleEpbInDraftChange"
        @conceptTypeChange="conceptTypeChange"
        @immediatelyAvailable="immediatelyAvailable"
      >
        <FormulateErrors />
        <div class="tw-flex tw-flex-row tw-space-x-4">
          <FormulateInput
            type="submit"
            :disabled="isLoading"
            :input-class="['tw-w-full']"
            :outer-class="['tw-w-full md:tw-w-auto']"
          >
            <i
              :class="[
                'fas tw-mr-1',
                isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
              ]"
            />
            Opslaan
          </FormulateInput>
          <FormulateInput
            type="button"
            :input-class="['tw-bg-error tw-w-full']"
            :outer-class="['tw-w-full md:tw-w-auto']"
            @click="goBack"
          >
            <i class="fa fa-times tw-mr-2"/> Annuleren
          </FormulateInput>
        </div>
      </FormulateForm>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import getProjectForm from '@/forms/schemas/portfolio/project'
import getPropertyForm from '@/forms/schemas/portfolio/property'

import { errorModal } from '@/modalMessages'

import { getReasons } from '@/services/apiService'
import { getRentalTerms } from '@/services/properties'

export default {
  name: 'PortfolioEdit',
  data () {
    return {
      form: {},
      values: {}
    }
  },
  watch: {
    formName: {
      immediate: true,
      handler (name) {
        return this.loadForm(name)
      }
    }
  },
  computed: {
    ...mapGetters('properties', ['getPropertyById', 'getProjectById']),

    entityId () {
      return this.$route.params.id
    },
    entityType () {
      return this.$route.meta.entity_type
    },
    entity () {
      return this.entityType === 'property'
        ? this.getPropertyById(this.entityId)
        : this.getProjectById(this.entityId)
    },
    formName () {
      return this.$route.params.formName
    },
    backRoute () {
      return this.$route.meta.backRoute
    },
    getForm () {
      return this.entityType === 'property' ? getPropertyForm : getProjectForm
    }
  },
  methods: {
    goBack () {
      this.$router.push(this.backRoute)
    },
    streetSelected (street) {
      if (street?.city) this.values.city = street.city
      if (street?.number) this.values.number = street.number
    },
    handleEpbInDraftChange () {
      document.querySelector('#epbDetailsGroup').classList.toggle('tw-hidden')
    },
    conceptTypeChange (e) {
      // If concept type is 3 (= Rent-to-buy), then show the field to fill out the rent to buy rental price
      if (parseInt(e.target.value) !== 3) document.querySelector('#rentToBuyPrice').classList.add('tw-hidden')
      else document.querySelector('#rentToBuyPrice').classList.remove('tw-hidden')
      // If concept type is 6 (= Do it yourself), then show the field to select the bid type
      if (parseInt(e.target.value) !== 6) document.querySelector('#bidType').classList.add('tw-hidden')
      else document.querySelector('#bidType').classList.remove('tw-hidden')
    },
    immediatelyAvailable (value) {
      const availableFromInput = document.querySelector('#availableFrom').classList
      if (value) {
        this.$set(this.values, 'available_from', '')
        availableFromInput.add('tw-bg-gray-cc', 'tw-pointer-events-none')
      } else {
        availableFromInput.remove('tw-bg-gray-cc', 'tw-pointer-events-none')
      }
    },

    async handleFormContext (context) {
      if (!context) return
      if (context === 'dossier') {
        const terminationReasonsParams = { type__key: 'termination_reason' }
        const [rentalTermsResponse, terminationReasonsResponse] = await Promise.all([getRentalTerms({ property: this.entityId }), getReasons({ params: terminationReasonsParams })])
        const rentalTermOptions = rentalTermsResponse.data.results.map(item => { return { value: item.id, label: item.name } })
        const terminationReasonOptions = terminationReasonsResponse.data.results.map(item => { return { value: item.id, label: item.name } })
        this.form.schema.forEach(
          schema => {
            schema.children.forEach(
              child => {
                if (child.name === 'rental_term') {
                  child.options = rentalTermOptions
                } else if (child.name === 'termination_reason') {
                  child.options = terminationReasonOptions
                }
              }
            )
          }
        )
        return [rentalTermsResponse, terminationReasonsResponse]
      }
    },

    async loadForm (name) {
      const form = await this.getForm(name, this.entity)
      this.form = { key: name, name, ...form }
      return form
    },
    async loadData () {
      try {
        const values = await this.form?.actions?.load(this.entityId)
        const context = values?.formContext
        await this.handleFormContext(context)
        this.values = values
        const epbDetailsGroup = document.querySelector('#epbDetailsGroup')
        if (epbDetailsGroup && !values?.epb_in_draft) epbDetailsGroup.classList.remove('tw-hidden')
        const bidType = document.querySelector('#bidType')
        if (bidType && values?.type === 6) bidType.classList.remove('tw-hidden')
        const rentToBuyPrice = document.querySelector('#rentToBuyPrice')
        if (rentToBuyPrice && values?.type === 3) rentToBuyPrice.classList.remove('tw-hidden')
        return values
      } catch (error) {
        this.$formulate.handle(error, this.formName)
      }
    },
    async submit (data) {
      try {
        const response = await this.form?.actions?.submit(this.entityId, data)
        this.goBack()
        return response
      } catch (error) {
        this.$formulate.handle(error, this.formName)
        const errorMap = {
          STATUS_CANNOT_SWITCH_TO_PROSPECT: 'Status van het pand kan niet terug op prospect gezet worden.',
          STATUS_CANNOT_SWITCH_TO_DOSSIER_PREPARATION: 'Status van het pand kan niet manueel op dossieropbouw gezet worden.',
          TYPE_CANNOT_BE_CHANGED_ACTIVE_PUBLICATION: 'Pandtype kan niet gewijzigd worden omdat er actieve publicaties zijn.'
        }
        return errorModal(
          errorMap[error.response?.data?.status?.code] || errorMap[error.response?.data?.type?.code] || 'Er ging iets mis bij het wijzigen van het pand.')
      }
    }
  }
}
</script>
