<template>
  <div>
    <h2 class="tw-mt-2 tw-mb-8">
      <router-link
        :to="{ name: 'PropertyFinancial' }"
        title="Ga terug"
        class="!tw-text-tg-color hover:tw-opacity-80"
      >
        <i class="fal fa-arrow-left tw-mr-2" />
      </router-link>
      Aankoopkosten {{ isRental ? '(te huur)' : (isSales ? '(te koop)' : '') }}
    </h2>
    <FormulateForm
      #default="{ isLoading }"
      v-model="values"
      name="editPurchaseCosts"
      invalid-message="Gelieve de verplichte velden correct in te vullen."
      class="tw-max-w-xl"
      @submit="submit"
    >
      <div class="tw-flex tw-flex-wrap tw-gap-4">
        <FormulateInput
          v-if="isSales"
          v-model="isVatSystem"
          type="checkbox"
          name="is_vat_system"
          label="Btw-stelsel"
          outer-class="tw-m-0"
        />
        <FormulateInput
          v-show="isVatSystem"
          type="radio"
          name="vat_rate"
          label="BTW-type:"
          :options="{ 6: '6%', 12: '12%', 21: '21%' }"
          :wrapper-class="['tw-flex tw-flex-row tw-gap-2 tw-items-center']"
          outer-class="tw-m-0"
        />
      </div>

      <div v-show="splitPriceInputs" class="tw-my-4 tw-grid md:tw-grid-cols-2 tw-gap-4">
        <FormulateInput
          type="number"
          name="price_land_share"
          label="Prijs grondaandeel"
          placeholder="Prijs grondaandeel"
          :validation="splitPriceInputs ? 'bail|landShareOrInfrastructureIsRequired|number|min:0' : 'bail|number|min:0'"
          step="any"
          min="0"
          lang="nl"
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="number"
          name="price_infrastructure"
          label="Prijs constructiewaarde"
          placeholder="Prijs constructiewaarde"
          :validation="splitPriceInputs ? 'bail|landShareOrInfrastructureIsRequired|number|min:0' : 'bail|number|min:0'"
          step="any"
          min="0"
          lang="nl"
          outer-class="tw-m-0"
        />
      </div>
      <FormulateInput
        v-show="!splitPriceInputs"
        type="number"
        name="price"
        label="Aankoopbedrag"
        placeholder="Aankoopbedrag"
        validation="bail|number|min:0"
        step="any"
        min="0"
        lang="nl"
      />
      <FormulateInput
        type="checkbox"
        name="publish_price"
        label="Prijs zichtbaarheid"
      />
      <div class="tw-mt-4 tw-mb-8 tw-grid md:tw-grid-cols-2 tw-gap-4">
        <template v-if="isSales">
          <FormulateInput
            type="number"
            name="notary_costs_estimate"
            label="Notariskosten"
            placeholder="Notariskosten"
            help="Hier kan je zelf de notariskosten invullen. Indien 0 wordt € 4000 als standaard genomen."
            validation="bail|number|min:0"
            step="any"
            min="0"
            lang="nl"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="number"
            name="connection_costs_estimate"
            label="Raming aansluitingskosten"
            placeholder="Raming aansluitingskosten"
            validation="bail|number|min:0"
            step="any"
            min="0"
            lang="nl"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="number"
            name="other_costs"
            label="Overige aankoopkosten"
            placeholder="Overige aankoopkosten"
            validation="bail|number|min:0"
            step="any"
            min="0"
            lang="nl"
            outer-class="tw-m-0"
          />
        </template>
        <template v-if="isRental">
          <FormulateInput
            type="number"
            name="cost_tenancy_inspection"
            label="Kostprijs plaatsbeschrijving"
            placeholder="Kostprijs plaatsbeschrijving"
            validation="bail|number|min:0"
            step="any"
            min="0"
            lang="nl"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="number"
            name="rental_deposit"
            label="Huurwaarborg"
            placeholder="Huurwaarborg"
            validation="bail|number|min:0"
            step="any"
            min="0"
            lang="nl"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="number"
            name="co_leasing"
            label="Medeverhuring"
            placeholder="Medeverhuring"
            validation="bail|number|min:0"
            step="any"
            min="0"
            lang="nl"
            outer-class="tw-m-0"
          />
        </template>
      </div>
      <FormulateErrors />
      <div class="tw-flex tw-flex-row tw-space-x-4">
        <FormulateInput
          type="submit"
          :disabled="isLoading"
          :input-class="['tw-w-full']"
          :outer-class="['tw-w-full md:tw-w-auto']"
        >
          <i
            :class="[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
            ]"
          />
          Opslaan
        </FormulateInput>
        <FormulateInput
          type="button"
          :input-class="['tw-bg-error tw-w-full']"
          :outer-class="['tw-w-full md:tw-w-auto']"
          @click="goBack"
        >
          <i class="fa fa-times tw-mr-2"/> Annuleren
        </FormulateInput>
      </div>
    </FormulateForm>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getPropertyFinancial, updatePropertyFinancial } from '@/services/properties'
import { successModal, errorModal } from '@/modalMessages'
import { propertyIsSales, propertyIsRental } from '@/utils/properties'

export default {
  name: 'PropertyFinancialEditPurchaseCosts',
  data () {
    return {
      values: {},
      isVatSystem: null
    }
  },
  computed: {
    ...mapGetters('properties', ['getPropertyById']),

    propertyId () {
      return this.$route.params.id
    },
    property () {
      return this.getPropertyById(this.propertyId)
    },
    isSales () {
      return propertyIsSales(this.property)
    },
    isRental () {
      return propertyIsRental(this.property)
    },
    splitPriceInputs () {
      return this.isSales && this.isVatSystem
    }
  },
  created () {
    this.loadFinancialData()
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'PropertyFinancial' })
    },
    async loadFinancialData () {
      try {
        const response = await getPropertyFinancial(this.propertyId)
        const { price, vat_rate, ...payload } = response.data
        // Need to convert vat_rate to a String, for the radio options to get the correct value, because HTML radio inputs work based on string values.
        this.values = { price: price.price, publish_price: price.published, vat_rate: vat_rate?.toString(), ...payload }
        return response
      } catch (error) {
        errorModal('Fout bij het laden van de financiële gegevens, probeer het opnieuw.')
      }
    },
    async submit (data) {
      try {
        // We add the if/else conditions to prevent updating the other price fields accidentally, because of switching the inputs due to `is_vat_system` checkbox.
        if (this.splitPriceInputs) delete data.price
        else {
          delete data.price_land_share
          delete data.price_infrastructure
        }

        const response = await updatePropertyFinancial(this.propertyId, data)
        successModal('De wijzigingen aan de financiële gegevens zijn succesvol opgeslagen')
        this.goBack()
        return response
      } catch (error) {
        this.$formulate.handle(error, 'editPurchaseCosts')
        errorModal('Er ging iets fout bij het wijzigen van de financiële gegevens van dit pand. Gelieve nogmaals te proberen.')
      }
    }
  }
}
</script>

<style scoped>
/* To make the vat_rate radio options inline */
/deep/ div[role="group"] {
  @apply tw-flex tw-flex-wrap tw-gap-x-2;
}
/deep/ .formulate-input-group-item {
  @apply tw-my-0;
}
</style>
