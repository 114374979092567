// const getRegionalLegalForm = region => region === 1
//   ? import(/* webpackChunkName: "PropertyBuildingEditLegalBrussels" */ './propertyLegalBrussels')
//   : import(/* webpackChunkName: "PropertyBuildingEditLegal" */ './propertyLegal')

const isRental = [3, 11] // Statuses that correspond to a rental property
const rentalTransactionType = 3 // Statuses that correspond to a rental property

const getFinancialOthersForm = status => isRental.includes(status)
  ? import(/* webpackChunkName: "PropertyBuildingEditFinancialOthersRental" */ './financial/financialOthersRental')
  : import(/* webpackChunkName: "PropertyBuildingEditFinancialOthersSales" */ './financial/financialOthersSales')

const getFinancialInvestmentForm = status => isRental.includes(status)
  ? import(/* webpackChunkName: "PropertyBuildingEditFinancialInvestmentRental" */ './financial/financialInvestmentRental')
  : import(/* webpackChunkName: "PropertyBuildingEditFinancialInvestmentSales" */ './financial/financialInvestmentSales')

const getDossierForm = transaction_type => transaction_type === rentalTransactionType
  ? import(/* webpackChunkName: "PropertyBuildingEditDossierRental" */ './dossierRental')
  : import(/* webpackChunkName: "PropertyBuildingEditDossierSales" */ './dossierSales')

const propertyBuildingForms = {
  general: () => import(/* webpackChunkName: "PropertyBuildingEditGeneral" */ './general'),
  concept: () => import(/* webpackChunkName: "PropertyBuildingEditConcept" */ './concept'),
  comfort: () => import(/* webpackChunkName: "PropertyBuildingEditComfort" */ './comfort'),
  commercial: () => import(/* webpackChunkName: "PropertyBuildingEditCommercial" */ './commercial'),
  dossier: ({ transaction_type }) => getDossierForm(transaction_type),
  features: () => import(/* webpackChunkName: "PropertyBuildingEditFeatures" */ './features'),
  technical: () => import(/* webpackChunkName: "PropertyBuildingEditTechnical" */ './technical')
}

const propertyGeneralForms = {
  address: () => import(/* webpackChunkName: "PropertyGeneralEditAddress" */ './address'),
  'notary-buyer': () => import(/* webpackChunkName: "PropertyGeneralEditNotaryBuyer" */ './notaryBuyer'),
  'notary-owner': () => import(/* webpackChunkName: "PropertyGeneralEditNotaryOwner" */ './notaryOwner'),
  syndic: () => import(/* webpackChunkName: "PropertyGeneralEditSyndic" */ './syndic'),
  renters: () => import(/* webpackChunkName: "PropertyGeneralEditRenters" */ './renters'),
  buyers: () => import(/* webpackChunkName: "PropertyGeneralEditBuyers" */ './buyers'),
  collaborators: () => import(/* webpackChunkName: "PropertyGeneralEditCollaborators" */ './collaborators'),
  offices: () => import(/* webpackChunkName: "PropertyGeneralEditOffices" */ './offices')
}

const propertyFinancialForms = {
  investment: ({ status }) => getFinancialInvestmentForm(status),
  cadastral: () => import(/* webpackChunkName: "PropertyFinancialEditCadastral" */ './financial/financialCadastral'),
  'fixed-costs': () => import(/* webpackChunkName: "PropertyFinancialEditFixedCosts" */ './financial/financialFixedCosts'),
  others: ({ status }) => getFinancialOthersForm(status)
}

const forms = { ...propertyGeneralForms, ...propertyBuildingForms, ...propertyFinancialForms }

async function getForm (name, property) {
  // Find the form and import it
  const response = await forms[name]?.(property)
  return response?.default
}

export default getForm
